<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">E-mails</h4>
          <div class="page-title-right">
            <b-button
              variant="success"
              v-b-tooltip.hover
              title="Adicionar E-mail"
              @click.prevent="adicionar()"
            >
              <i class="bx bx-plus font-size-16 align-middle mr-2"></i>
              Adicionar
            </b-button>
            <b-button
              class="ml-2"
              variant="info"
              v-b-tooltip.hover
              title="Acessar E-mail"
              @click.prevent="abrirEmail()"
            >
              <i class="bx bx-mail-send font-size-16 align-middle"></i>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="loading">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="error">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-danger my-2">
              <h5 class="mb-3">Erro ao carregar dados!</h5>
              <img width="250" src="@/assets/images/error-img.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="contas.length <= 0">
      <div class="col-12">
        <div class="card">
          <div class="card-body py-5 text-center">
            <h5 class="mb-3">Nenhum item encontrado!</h5>
            <img width="250" src="@/assets/images/not-data.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12">
        <div class="card">
          <div class="card-body text-left">
            <h5>E-mails</h5>
            <hr />
            <div class="table-responsive mb-0">
              <table class="table table-centered table-nowrap">
                <thead class="thead-light text-left">
                  <tr>
                    <th>ID</th>
                    <th>E-mail</th>
                    <th>Data</th>
                    <th>Espaço em Disco</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody class="text-left">
                  <tr v-for="data in contas" :key="data.id">
                    <td>{{ data.id }}</td>
                    <td>
                      {{ data.name }}@{{ $store.state.auth.user.dominio }}
                    </td>
                    <td>
                      {{ data.created_at | moment('DD/MM/YYYY') }} às
                      {{ data.created_at | moment('HH:mm') }}
                    </td>
                    <td>
                      {{
                        data.disk == '' || data.disk == 0
                          ? 'Ilimitado'
                          : data.disk + ' MB'
                      }}
                    </td>
                    <td>
                      <button
                        class="btn btn-info ml-2"
                        v-b-tooltip.hover.top
                        title="Alterar Senha"
                        @click.prevent="atualizarPassword(data)"
                      >
                        <i class="fas fa-pencil-alt text-white"></i>
                      </button>
                      <button
                        class="btn btn-danger ml-2"
                        v-b-tooltip.hover.top
                        title="Remover"
                        @click.prevent="removerItem(data)"
                      >
                        <i class="fas fa-trash-alt text-white"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner" class="text-info"></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
    <b-modal v-model="showModalPass" title="Alterar Senha" centered>
      <div class="row">
        <div class="col-12">
          <label>
            Nova Senha
          </label>
          <b-form-group>
            <b-form-input
              v-model="form.password"
              placeholder="Nova Senha"
              for="text"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="danger" @click.prevent="cancelar()">
          Cancelar
        </b-button>
        <b-button @click.prevent="updateData()" variant="success">
          Salvar
        </b-button>
      </template>
    </b-modal>
    <b-modal v-model="showModal" title="Criar e-mail" centered>
      <div class="row">
        <div class="col-12">
          <label>
            <span class="mr-1 text-danger">*</span>
            Usuário
          </label>
          <b-form-group>
            <b-form-input
              placeholder="Usuário"
              for="text"
              v-model="form.name"
              :class="{ 'is-invalid': form && $v.form.name.$error }"
            ></b-form-input>
            <small>@{{ $store.state.auth.user.dominio }}</small>
          </b-form-group>
        </div>
        <div class="col-12 mt-1">
          <label>
            <span class="mr-1 text-danger">*</span>
            Senha
          </label>
          <b-form-group>
            <b-form-input
              placeholder="Senha"
              for="password"
              v-model="form.password"
              :class="{ 'is-invalid': form && $v.form.password.$error }"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12 mt-1">
          <label>
            Espaço em Disco
          </label>
          <b-form-group>
            <b-form-input
              placeholder="Espaço em Disco"
              for="text"
              v-model="form.disk"
            ></b-form-input>
            <small>0 para ilimitado</small>
          </b-form-group>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="danger" @click="showModal = false">
          Cancelar
        </b-button>
        <b-button variant="success" @click.prevent="salvarData()">
          Criar E-mail
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      loading: true,
      error: false,
      showModal: false,
      showModalPass: false,
      contas: [],
      form: {
        name: null,
        password: null,
      },
      filtro: {
        limit: 20,
        page: 1,
      },
    }
  },
  validations: {
    form: {
      name: { required },
      password: { required },
    },
  },
  methods: {
    ...mapActions('email', [
      'ActionEmailCreate',
      'ActionEmailUpdate',
      'ActionEmailAll',
      'ActionEmailDelete',
    ]),
    salvarData() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitEmail(0)
      }
    },
    updateData() {
      this.submitEmail(1)
    },
    atualizarPassword(data) {
      this.form = data
      this.showModalPass = true
    },
    cancelar() {
      this.showModalPass = false
      this.showModal = false
      this.form = {
        name: null,
        password: null,
        disk: null,
      }
    },
    adicionar() {
      this.showModal = true
      this.form = {
        name: null,
        password: null,
        disk: null,
      }
    },
    removerItem(data) {
      this.$swal({
        icon: 'warning',
        title: 'Confirmação',
        text: 'Deseja Remover Este Item?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `SIM`,
        denyButtonText: `NÃO`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.submitEmail(2, data)
        }
      })
    },
    async infiniteHandler($state) {
      try {
        await this.ActionEmailAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page += 1
            this.contas.push(...res.data.data.list)
            $state.loaded()
          } else {
            $state.complete()
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.loading = false
        this.error = true
        this.contas = []
        $state.complete()
      }
    },
    async submitEmail(tipo, data) {
      try {
        if ((this.form.password == null || this.form.password == '') && tipo != 2) {
          this.$swal({
            title: 'Atenção',
            text: 'Digite a nova senha para o e-mail!',
            icon: 'info',
          })
        } else {
          this.$swal({
            title: 'Aguarde!',
            text: 'Salvando Dados',
            icon: 'info',
            showConfirmButton: false,
          })
          if (tipo == 0) {
            await this.ActionEmailCreate(this.form).then(res=>{
              this.contas.push(res.data.data)
            })
          } else if (tipo == 1) {
            await this.ActionEmailUpdate(this.form)
          } else if (tipo == 2) {
            await this.ActionEmailDelete(data)
            this.contas.splice(this.contas.indexOf(data), 1)
          }

          this.$swal({
            title: 'Sucesso',
            text: 'Ação realizada com sucesso!',
            icon: 'success',
            timer: 2000,
            timerProgressBar: true,
          }).then((_) => {
            this.showModal = false
            this.showModalPass = false
            this.form = {
              name: null,
              password: null,
            }
          })
        }
      } catch (e) {
        console.log(e)
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    abrirEmail() {
      window.open(
        'https://email.' + this.$store.state.auth.user.dominio,
        '_blank',
      )
    },
  },
}
</script>
